<template>
  <v-dialog v-model="show" max-width="500px" persistent scrollable>
    <v-card :loading="save_data">
      <v-card-title class="text-h6 primary">
        {{ getTitle() }}
        <v-spacer></v-spacer>
        <v-btn
            class="mx-0"
            icon
            @click="$emit('cancel')"
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <!--  из ПРИМЕРА  -->
      <v-card-text style="height: 400px">

        <v-form ref="form">
          <v-container>
            <v-row>
              <!--
              что бы сделать 3 поля в линию надо использовать 3 раза
                    <v-col cols="12" sm="6" md=4>
                    <v-text-field/>
                    </v-col>
              разметка
              https://vuetifyjs.com/en/components/dialogs/#form
              код
              https://vuetifyjs.com/en/components/text-fields/#custom-validation
              -->
              <v-col cols="12">
                <v-text-field
                    v-model="item_edit.name"
                    label="Полное название"
                    placeholder="Слитки железа"
                    maxlength="50"
                    counter
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                    v-model="item_edit.shot_name"
                    ref="shot_name"
                    label="Краткое название"
                    placeholder="Железо"
                    maxlength="15"
                    counter
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                    v-model="item_edit.price"
                    label="Цена"
                    type="number"
                    placeholder="100"
                    suffix="฿"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                    v-model="item_edit.parameter"
                    ref="parameter"
                    label="Код"
                    placeholder="Ingot:Iron"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-select label="Тип"
                          v-model="item_edit.type"
                          :items="product_types"
                          item-text="[1]"
                          item-value="[0]"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="7">
                <v-checkbox
                    v-model="item_edit.block"
                    label="Блокировать продажу"
                    color="primary"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                    v-model="item_edit.quantity"
                    type="number"
                    label="Количество"
                    placeholder="50"
                    :suffix="suffix"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="item_edit.description"
                    label="Описание"
                    placeholder="Самый распространенный товар"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

      </v-card-text>
      <v-divider class="mt-0"></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="$emit('cancel')">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition>
          <v-tooltip
              v-if="formHasErrors"
              left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  class="my-0"
                  v-bind="attrs"
                  @click="resetForm"
                  v-on="on"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh form</span>
          </v-tooltip>
        </v-slide-x-reverse-transition>
        <v-btn color="blue darken-1" :disabled="saveBlock" text
               :loading="save_data"
               @click="submit">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
let normal = {}

export default {
  props: ['show', 'item'],
  data: function () {
    return {
      formHasErrors: false,
      item_edit: {
        name: null,
        shot_name: null,
        price: null,
        parameter: null,
        quantity: null,
        block: false,
        description: null,
        type: null
      },
      product_types: [['PRD', 'Продукт'], ['SRV', 'Услуга']],
      loading: false,
      save_data: false,
      confirm_user: false,
    }
  },
  watch: {
    show() {
      // window.console.log("========  watch Онлайн  ===========", this.item)
      this.clear()
      if (!this.item) return
      this.item_edit = this.$lib.copy(this.item)
      this.product_type_select = [this.item_edit.type]
    }
  },

  created() {
    normal = this.$lib.copy(this.$data)
  },
  methods: {
    getTitle() {
      if (!this.item) return "Новый продукт"
      return this.item.name
    },
    itemCreate() {
      this.save_data = true;
      this.$axios.post(this.$url.shop_project_products, this.item_edit )
          .then(response => {
            this.$emit('saved', response.data)
          })
          .finally(() => {
            this.save_data = false;
          })
    },
    itemUpdate() {
      const param = this.$lib.compare(this.item_edit, this.item)
      if (!param) return
      this.save_data = true;
      this.$axios.patch(this.$url.shop_project_products + `${this.item.id}/`, param)
          .then(response => {
            this.$emit('saved', response.data)
          })
          .finally(() => {
            this.save_data = false;
          })
    },
    submit_off() {
      window.console.log("========  $refs  ===========", this.$refs)

      // this.formHasErrors = false
      // Object.keys(this.$refs.form).forEach(f => {
      // window.console.log("========  f  ===========", f)
      // if (!this.form[f]) this.formHasErrors = true
      // this.$refs[f].validate(true)
      // })
    },
    submit() {
      if (this.item) this.itemUpdate()
      else this.itemCreate()
    },
    clear() {
      Object.assign(this.$data, normal);
    }
  },
  computed: {
    suffix: function () {
      if (this.item_edit.type === 'SRV') return 'дней'
      return 'штук'
    },
    saveBlock: function () {
      return false
      // if (this.item) { // редактируем элемент
      //   if ((!this.product_type_select) || (this.product_type_select[0] === this.item.type)) return true
      // }
      // if (!this.item) { // создаем элемент
      //   if (!this.product_type_select) return true
      //   if (!this.confirm_user) return true
      // }
      // return false
    },
  }
}
</script>
